import { Observable } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { SessionService } from '../_service/session/session.service';

@Injectable()
export class HttpHeaderInterceptor implements HttpInterceptor {
    constructor(private sessionService: SessionService) {
    }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.sessionService.userSession$.pipe(
            take(1),
            mergeMap(userSession => {
                if (userSession.isLoggedIn()) {
                    const finalHeaders = req.headers.set('Authorization', userSession.getAuthToken());
                    const clonedRequest = req.clone({ headers: finalHeaders });
                    return next.handle(clonedRequest);
                }

                return next.handle(req);
            })
        );
    }
}
