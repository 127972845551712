<ngx-loading-bar></ngx-loading-bar>
<router-outlet></router-outlet>
<div class="app-loader" *ngIf="asyncLoadCount">
    <div class="app-loader__container">
        <div>loading modules</div>
        <div class="following-balls">
            <div class="following-balls__ball following-balls__ball_1"></div>
            <div class="following-balls__ball following-balls__ball_2"></div>
            <div class="following-balls__ball following-balls__ball_3"></div>
            <div class="following-balls__ball following-balls__ball_4"></div>
        </div>
    </div>
</div>
