import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginGuard } from '@ls-core';

const routes: Routes = [
    {
        path: 'login', canActivate: [LoginGuard],
        loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule),
    },
    {
        path: 'activation', children: [
            { path: '', pathMatch: 'full', redirectTo: '/' },
            {
                path: '', canActivate: [LoginGuard],
                loadChildren: () => import('./pages/activation/activation.module').then(m => m.ActivationModule),
            },
        ],
    },
    {
        path: 'info', loadChildren: () => import('./pages/info/info.module').then(m => m.InfoModule),
    },
    {
        path: 'print', loadChildren: () => import('./pages/print-pages/print-pages.module').then(m => m.PrintPagesModule),
    },
    {
        path: 'export/public-bill', loadChildren: () => import('./pages/public-order-bill/public-order-bill.module').then(m => m.PublicOrderBillModule),
    },
    {
        path: 'export/order-widget', loadChildren: () => import('./pages/public-order-widget/public-order-widget.module').then(m => m.PublicOrderWidgetModule),
    },
    {
        path: 'example/three-js', loadChildren: () => import('./pages/example-three-js/example-three-js.module').then(m => m.ExampleThreeJsModule),
    },
    {
        path: '', loadChildren: () => import('./pages/main/main.module').then(m => m.MainModule),
    },
    { path: '**', redirectTo: '/' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
