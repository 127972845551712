import { GlobalConfig, ToastrModule } from 'ngx-toastr';

import { ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { ConsoleLoggerTransport } from '@ls-front/sharable';
import { environment } from '@ls-front/env';

import {
    CustomCacheService, ErrorHandlerService, LoggerService, LoginGuard, MainGuard, MessengerService, PhoneMaskService, SentryLoggerTransport, SettingsService,
    TokenGuard,
} from './';
import { HttpHeaderInterceptor } from './interceptors/http-header.interceptor';

const toastrCustomConfig: Partial<GlobalConfig> = {
    positionClass: 'toast-top-full-width',
    maxOpened: 5,
    newestOnTop: true,
    timeOut: 5000,
    autoDismiss: true,
    enableHtml: true,
    tapToDismiss: true,
};

@NgModule({
    imports: [
        ToastrModule.forRoot(toastrCustomConfig),
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: HttpHeaderInterceptor, multi: true },
        { provide: ErrorHandler, useClass: ErrorHandlerService },

        {
            /** @see https://material.angular.io/components/form-field/overview#form-field-appearance-variants */
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' },
        },

        CustomCacheService, MessengerService, PhoneMaskService, SettingsService,
        LoggerService,
        LoginGuard, MainGuard, TokenGuard,
        provideHttpClient(withInterceptorsFromDi()),
    ],
})
export class CoreModule {
    constructor(logger: LoggerService, @Optional() @SkipSelf() parentModule?: CoreModule) {
        if (parentModule) {
            throw new Error(
                'CoreModule is already loaded. Import it in the AppModule only');
        }

        logger.addTransport(new ConsoleLoggerTransport());
        if (environment.production) {
            logger.addTransport(new SentryLoggerTransport(false));
            logger.log('Lisa10 started');
        } else {
            logger.log('Lisa10 started - dev');
        }
    }
}
