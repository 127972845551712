import * as sentry from '@sentry/angular';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorHandler, NgModule, inject, provideAppInitializer } from '@angular/core';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { Router } from '@angular/router';

import { CoreModule } from './core/core.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        LoadingBarHttpClientModule,
        LoadingBarRouterModule,
        AppRoutingModule,
        CoreModule,
    ],
    declarations: [AppComponent],
    bootstrap: [AppComponent],

    /**
     * Register Sentry Providers
     * The Sentry Angular SDK exports a couple of Angular providers that are necessary to fully instrument your application.
     * @see https://docs.sentry.io/platforms/javascript/guides/angular/#register-sentry-providers
     */
    providers: [
        {
            provide: ErrorHandler,
            useValue: sentry.createErrorHandler(),
        },
        {
            provide: sentry.TraceService,
            deps: [Router],
        },
        provideAppInitializer(() => {
            inject(sentry.TraceService);
        }),
    ],
})
export class AppModule {
}
